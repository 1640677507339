import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataResponse } from '@standardkit/core';
import { catchError, Observable, throwError } from 'rxjs';
import { EventConst } from '../constants';
import { CreateTicketRequest, TicketSummaryInterface } from '../interfaces';
import { ApiResources } from '../resources';
import { CreateTicketResponse, TicketMessageResponse, TicketResponse } from '../responses';
import { ApiService } from './api.service';
import { EventService } from './event.service';

@Injectable({ providedIn: 'root' })
export class TicketService {
  constructor(
    private apiService: ApiService,
    private eventService: EventService,
  ) {}

  public createTicket$(request: CreateTicketRequest): Observable<CreateTicketResponse> {
    const url = ApiResources.Tickets;

    return this.apiService.post$<CreateTicketResponse>(url, request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.eventService.log(EventConst.Error, {
          endpoint: `POST /${ApiResources.Tickets}`,
          error: error.name,
          status: error.status,
          errorMessage: error.message,
          ...request,
        });
        return throwError(() => error);
      }),
    );
  }

  public getTickets$(): Observable<DataResponse<TicketSummaryInterface>> {
    const url = ApiResources.Tickets + '?sort[createdAt]=desc';

    return this.apiService.get$<DataResponse<TicketSummaryInterface>>(url);
  }

  public getTicket$(id: string): Observable<TicketResponse> {
    const url = `${ApiResources.Tickets}/${id}`;

    return this.apiService.get$<TicketResponse>(url);
  }

  public reply$(ticketId: string, message: string): Observable<TicketMessageResponse> {
    const url = `${ApiResources.Tickets}/${ticketId}/${ApiResources.TicketMessages}`;
    const request = { description: message };

    return this.apiService.post$(url, request);
  }

  public update$(ticketId: string, data: Partial<TicketResponse>): Observable<TicketResponse> {
    const url = `${ApiResources.Tickets}/${ticketId}`;

    return this.apiService.patch$(url, data);
  }
}
