import { Inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  constructor(@Inject(Clipboard) private clipboard: Clipboard) {}

  public copy$(text: string): Observable<void> {
    const typeHTML = 'text/html';
    const typePlain = 'text/plain';

    const clipboardItem = new ClipboardItem({
      [typeHTML]: new Blob([text], { type: typeHTML }),
      [typePlain]: new Blob([text], { type: typePlain }),
    });

    return from(this.clipboard.write([clipboardItem]));
  }
}
