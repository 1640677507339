import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationRoutes } from '@werfy/routing';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { ApiResources, AuthenticationResources } from '../resources';
import { AuthenticationService } from '../services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private router: Router,
  ) {}

  public intercept(request: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {
    return handler.handle(request).pipe(
      catchError((error: HttpErrorResponse): Observable<HttpEvent<any>> => {
        if (error.status !== HttpStatusCode.Unauthorized) {
          return throwError(() => error);
        }

        const refreshResource = [
          ApiResources.Authentication,
          AuthenticationResources.Session,
          AuthenticationResources.Refresh,
        ].join('/');

        if (request.url.endsWith(refreshResource)) {
          return this.handleSessionEnd(error);
        }

        const authenticationService = this.injector.get(AuthenticationService);
        return authenticationService.refreshSession$().pipe(
          catchError((error: unknown) => this.handleSessionEnd(error)),
          switchMap(() => handler.handle(request)),
        );
      }),
    );
  }

  private handleSessionEnd(error: unknown): Observable<never> {
    this.router.navigate([ApplicationRoutes.Root], { onSameUrlNavigation: 'reload' }).then();
    return throwError(() => error);
  }
}
