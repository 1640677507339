import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ApiResources } from '../resources';
import { EventRequest } from '../requests';
import { EventType } from '../types';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { v4 } from 'uuid';

const SESSION_KEY = 'session_id';

@Injectable({ providedIn: 'root' })
export class EventService {
  private currentRoute: string = '';

  constructor(
    private apiService: ApiService,
    private router: Router,
  ) {
    this.getSessionId();
    this.updateRoute();
    this.listenToNavigationEvents();
  }

  public initialize(): void {
    console.log('EventService initialized');
  }

  public log(type: EventType, data: Record<string, string | number> = {}): void {
    const url = ApiResources.Events;
    const request: EventRequest = this.createRequest(type, data);

    this.apiService.post$<void>(url, request).subscribe(() => undefined);
  }

  private updateRoute(): void {
    this.currentRoute = this.router.url;
  }

  private listenToNavigationEvents(): void {
    this.router.events.pipe(filter((event: unknown) => event instanceof NavigationEnd)).subscribe(() => {
      this.updateRoute();
    });
  }

  private createRequest(type: EventType, data: Record<string, string | number>): EventRequest {
    return {
      sessionId: this.getSessionId(),
      type,
      userAgent: window.navigator.userAgent,
      currentRoute: this.currentRoute,
      data,
    };
  }

  private getSessionId(): string {
    let sessionId = sessionStorage.getItem(SESSION_KEY);
    if (!sessionId) {
      sessionId = v4();
      sessionStorage.setItem(SESSION_KEY, sessionId);
    }
    return sessionId;
  }
}
