import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService, LinkedInRedirectInterface } from '@werfy/core';
import { ApplicationRoutes, AuthenticationRoutes } from '@werfy/routing';

@Injectable({ providedIn: 'root' })
export class LinkedInService {
  constructor(private environmentService: EnvironmentService) {}

  public getRedirectUrl(page: string): string {
    const linkedInRedirectUrl = this.environmentService.getEnvironment().linkedInRedirectUrl;
    const clientId = this.environmentService.getEnvironment().linkedInClientId;
    const baseUrl = `${this.environmentService.getEnvironment().baseUrl}/${ApplicationRoutes.Authentication}`;
    const redirectUrl = `${baseUrl}/${page}/${AuthenticationRoutes.LinkedInRedirect}`;

    const parameters: LinkedInRedirectInterface = {
      response_type: 'code',
      client_id: clientId,
      scope: 'openid profile email',
      redirect_uri: redirectUrl,
    };

    return this.createUrlWithParameters(linkedInRedirectUrl, parameters);
  }

  private createUrlWithParameters<T>(url: string, queryParameters: T): string {
    const httpParams = new HttpParams({ fromObject: queryParameters as Extract<T, Record<string, string>> });

    return `${url}?${httpParams.toString()}`;
  }
}
