import { Injectable } from '@angular/core';
import { EnvironmentInterface } from '@werfy/core';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  public getEnvironment(): EnvironmentInterface {
    return environment;
  }
}
