export const ApiResources = {
  Attributes: 'attributes',
  Authentication: 'authentication',
  Campaigns: 'campaigns',
  Candidates: 'candidates',
  Events: 'events',
  Layouts: 'layouts',
  MessageTemplates: 'message-templates',
  Organizations: 'organizations',
  Profiles: 'profiles',
  Reset: 'reset',
  SegmentCategories: 'segment-categories',
  SentMessages: 'sent-messages',
  Suggestions: 'suggestions',
  TemplateVariables: 'template-variables',
  TicketMessages: 'messages',
  Tickets: 'tickets',
  Values: 'values',
} as const;
