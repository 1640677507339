import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UserResponse } from '../responses';
import { Store } from './store';

const LOGGED_IN_KEY = 'is-logged-in';

@Injectable({ providedIn: 'root' })
export class AuthenticationStore {
  private user$: Subject<UserResponse | undefined> = new BehaviorSubject<UserResponse | undefined>(undefined);

  constructor(private store: Store) {}

  public setLoggedIn(isLoggedIn: boolean): void {
    this.store.set<boolean>(LOGGED_IN_KEY, isLoggedIn);
  }

  public isLoggedIn(): boolean {
    return this.store.get<boolean | undefined>(LOGGED_IN_KEY) === true;
  }

  public getUser$(): Observable<UserResponse | undefined> {
    return this.user$.asObservable();
  }

  public setUser(user: UserResponse): void {
    this.user$.next(user);
  }
}
