import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataRequest, DataResponse, mapDataRequest } from '@standardkit/core';
import { EnvironmentService } from '@werfy/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApiService {
  private readonly baseUrl!: Readonly<string>;

  constructor(
    private httpClient: HttpClient,
    environmentService: EnvironmentService,
  ) {
    this.baseUrl = environmentService.getEnvironment().apiBaseUrl;
  }

  public get$<T>(resource: string): Observable<T> {
    const resourceUrl = `${this.baseUrl}/${resource}`;

    return this.httpClient.get<T>(resourceUrl);
  }

  public getData$<T>(resource: string, request: DataRequest<T>): Observable<DataResponse<T>> {
    const resourceUrl = `${this.baseUrl}/${resource}`;
    const params = mapDataRequest(request);

    return this.httpClient.get<DataResponse<T>>(resourceUrl, { params });
  }

  public post$<T>(resource: string, data?: any): Observable<T> {
    const resourceUrl = `${this.baseUrl}/${resource}`;

    return this.httpClient.post<T>(resourceUrl, data);
  }

  public put$<T>(resource: string, data: any = null): Observable<T> {
    const resourceUrl = `${this.baseUrl}/${resource}`;

    return this.httpClient.put<T>(resourceUrl, data);
  }

  public patch$<T>(resource: string, data: any = null): Observable<T> {
    const resourceUrl = `${this.baseUrl}/${resource}`;

    return this.httpClient.patch<T>(resourceUrl, data);
  }

  public delete$<T>(resource: string, data: any = null): Observable<T> {
    const resourceUrl = `${this.baseUrl}/${resource}`;

    return this.httpClient.delete<T>(resourceUrl, { body: data });
  }
}
