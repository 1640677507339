import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResources } from '../resources';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class DevService {
  constructor(private apiService: ApiService) {}

  public reset$(): Observable<void> {
    return this.apiService.post$<void>(ApiResources.Reset, { reset: true });
  }
}
