import { Injectable } from '@angular/core';
import { CanMatch, GuardResult, MaybeAsync } from '@angular/router';
import { AuthenticationService } from '@werfy/core';

@Injectable({ providedIn: 'root' })
export class LoggedInGuard implements CanMatch {
  constructor(private authenticationService: AuthenticationService) {}

  public canMatch(): MaybeAsync<GuardResult> {
    const isLoggedIn = this.authenticationService.isLoggedIn();

    return isLoggedIn !== undefined ? isLoggedIn : this.authenticationService.isLoggedIn$();
  }
}
